#stars {
    width: 1px;
    height: 1px;
    background: transparent;
    animation: animStar 50s linear infinite;
    box-shadow: 779px 1331px #b65d8e, 324px 42px #b65d8e, 303px 586px #b65d8e,
    1312px 276px #b65d8e, 451px 625px #b65d8e, 521px 1931px #b65d8e, 1087px 1871px #b65d8e,
    36px 1546px #b65d8e, 132px 934px #b65d8e, 1698px 901px #b65d8e, 1418px 664px #b65d8e,
    1448px 1157px #b65d8e, 1084px 232px #b65d8e, 347px 1776px #b65d8e, 1722px 243px #b65d8e,
    1629px 835px #b65d8e, 479px 969px #b65d8e, 1231px 960px #b65d8e, 586px 384px #b65d8e,
    164px 527px #b65d8e, 8px 646px #b65d8e, 1150px 1126px #b65d8e, 665px 1357px #b65d8e,
    1556px 1982px #b65d8e, 1260px 1961px #b65d8e, 1675px 1741px #b65d8e,
    1843px 1514px #b65d8e, 718px 1628px #b65d8e, 242px 1343px #b65d8e, 1497px 1880px #b65d8e,
    1364px 230px #b65d8e, 1739px 1302px #b65d8e, 636px 959px #b65d8e, 304px 686px #b65d8e,
    614px 751px #b65d8e, 1935px 816px #b65d8e, 1428px 60px #b65d8e, 355px 335px #b65d8e,
    1594px 158px #b65d8e, 90px 60px #b65d8e, 1553px 162px #b65d8e, 1239px 1825px #b65d8e,
    1945px 587px #b65d8e, 749px 1785px #b65d8e, 1987px 1172px #b65d8e, 1301px 1237px #b65d8e,
    1039px 342px #b65d8e, 1585px 1481px #b65d8e, 995px 1048px #b65d8e, 524px 932px #b65d8e,
    214px 413px #b65d8e, 1701px 1300px #b65d8e, 1037px 1613px #b65d8e, 1871px 996px #b65d8e,
    1360px 1635px #b65d8e, 1110px 1313px #b65d8e, 412px 1783px #b65d8e, 1949px 177px #b65d8e,
    903px 1854px #b65d8e, 700px 1936px #b65d8e, 378px 125px #b65d8e, 308px 834px #b65d8e,
    1118px 962px #b65d8e, 1350px 1929px #b65d8e, 781px 1811px #b65d8e, 561px 137px #b65d8e,
    757px 1148px #b65d8e, 1670px 1979px #b65d8e, 343px 739px #b65d8e, 945px 795px #b65d8e,
    576px 1903px #b65d8e, 1078px 1436px #b65d8e, 1583px 450px #b65d8e, 1366px 474px #b65d8e,
    297px 1873px #b65d8e, 192px 162px #b65d8e, 1624px 1633px #b65d8e, 59px 453px #b65d8e,
    82px 1872px #b65d8e, 1933px 498px #b65d8e, 1966px 1974px #b65d8e, 1975px 1688px #b65d8e,
    779px 314px #b65d8e, 1858px 1543px #b65d8e, 73px 1507px #b65d8e, 1693px 975px #b65d8e,
    1683px 108px #b65d8e, 1768px 1654px #b65d8e, 654px 14px #b65d8e, 494px 171px #b65d8e,
    1689px 1895px #b65d8e, 1660px 263px #b65d8e, 1031px 903px #b65d8e, 1203px 1393px #b65d8e,
    1333px 1421px #b65d8e, 1113px 41px #b65d8e, 1206px 1645px #b65d8e, 1325px 1635px #b65d8e,
    142px 388px #b65d8e, 572px 215px #b65d8e, 1535px 296px #b65d8e, 1419px 407px #b65d8e,
    1379px 1003px #b65d8e, 329px 469px #b65d8e, 1791px 1652px #b65d8e, 935px 1802px #b65d8e,
    1330px 1820px #b65d8e, 421px 1933px #b65d8e, 828px 365px #b65d8e, 275px 316px #b65d8e,
    707px 960px #b65d8e, 1605px 1554px #b65d8e, 625px 58px #b65d8e, 717px 1697px #b65d8e,
    1669px 246px #b65d8e, 1925px 322px #b65d8e, 1154px 1803px #b65d8e, 1929px 295px #b65d8e,
    1248px 240px #b65d8e, 1045px 1755px #b65d8e, 166px 942px #b65d8e, 1888px 1773px #b65d8e,
    678px 1963px #b65d8e, 1370px 569px #b65d8e, 1974px 1400px #b65d8e, 1786px 460px #b65d8e,
    51px 307px #b65d8e, 784px 1400px #b65d8e, 730px 1258px #b65d8e, 1712px 393px #b65d8e,
    416px 170px #b65d8e, 1797px 1932px #b65d8e, 572px 219px #b65d8e, 1557px 1856px #b65d8e,
    218px 8px #b65d8e, 348px 1334px #b65d8e, 469px 413px #b65d8e, 385px 1738px #b65d8e,
    1357px 1818px #b65d8e, 240px 942px #b65d8e, 248px 1847px #b65d8e, 1535px 806px #b65d8e,
    236px 1514px #b65d8e, 1429px 1556px #b65d8e, 73px 1633px #b65d8e, 1398px 1121px #b65d8e,
    671px 1301px #b65d8e, 1404px 1663px #b65d8e, 740px 1018px #b65d8e, 1600px 377px #b65d8e,
    785px 514px #b65d8e, 112px 1084px #b65d8e, 1915px 1887px #b65d8e, 1463px 1848px #b65d8e,
    687px 1115px #b65d8e, 1268px 1768px #b65d8e, 1729px 1425px #b65d8e,
    1284px 1022px #b65d8e, 801px 974px #b65d8e, 1975px 1317px #b65d8e, 1354px 834px #b65d8e,
    1446px 1484px #b65d8e, 1283px 1786px #b65d8e, 11px 523px #b65d8e, 1842px 236px #b65d8e,
    1355px 654px #b65d8e, 429px 7px #b65d8e, 1033px 1128px #b65d8e, 157px 297px #b65d8e,
    545px 635px #b65d8e, 52px 1080px #b65d8e, 827px 1520px #b65d8e, 1121px 490px #b65d8e,
    9px 309px #b65d8e, 1744px 1586px #b65d8e, 1014px 417px #b65d8e, 1534px 524px #b65d8e,
    958px 552px #b65d8e, 1403px 1496px #b65d8e, 387px 703px #b65d8e, 1522px 548px #b65d8e,
    1355px 282px #b65d8e, 1532px 601px #b65d8e, 1838px 790px #b65d8e, 290px 259px #b65d8e,
    295px 598px #b65d8e, 1601px 539px #b65d8e, 1561px 1272px #b65d8e, 34px 1922px #b65d8e,
    1024px 543px #b65d8e, 467px 369px #b65d8e, 722px 333px #b65d8e, 1976px 1255px #b65d8e,
    766px 983px #b65d8e, 1582px 1285px #b65d8e, 12px 512px #b65d8e, 617px 1410px #b65d8e,
    682px 577px #b65d8e, 1334px 1438px #b65d8e, 439px 327px #b65d8e, 1617px 1661px #b65d8e,
    673px 129px #b65d8e, 794px 941px #b65d8e, 1386px 1902px #b65d8e, 37px 1353px #b65d8e,
    1467px 1353px #b65d8e, 416px 18px #b65d8e, 187px 344px #b65d8e, 200px 1898px #b65d8e,
    1491px 1619px #b65d8e, 811px 347px #b65d8e, 924px 1827px #b65d8e, 945px 217px #b65d8e,
    1735px 1228px #b65d8e, 379px 1890px #b65d8e, 79px 761px #b65d8e, 825px 1837px #b65d8e,
    1980px 1558px #b65d8e, 1308px 1573px #b65d8e, 1488px 1726px #b65d8e,
    382px 1208px #b65d8e, 522px 595px #b65d8e, 1277px 1898px #b65d8e, 354px 552px #b65d8e,
    161px 1784px #b65d8e, 614px 251px #b65d8e, 526px 1576px #b65d8e, 17px 212px #b65d8e,
    179px 996px #b65d8e, 467px 1208px #b65d8e, 1944px 1838px #b65d8e, 1140px 1093px #b65d8e,
    858px 1007px #b65d8e, 200px 1064px #b65d8e, 423px 1964px #b65d8e, 1945px 439px #b65d8e,
    1377px 689px #b65d8e, 1120px 1437px #b65d8e, 1876px 668px #b65d8e, 907px 1324px #b65d8e,
    343px 1976px #b65d8e, 1816px 1501px #b65d8e, 1849px 177px #b65d8e, 647px 91px #b65d8e,
    1984px 1012px #b65d8e, 1336px 1300px #b65d8e, 128px 648px #b65d8e, 305px 1060px #b65d8e,
    1324px 826px #b65d8e, 1263px 1314px #b65d8e, 1801px 629px #b65d8e, 1614px 1555px #b65d8e,
    1634px 90px #b65d8e, 1603px 452px #b65d8e, 891px 1984px #b65d8e, 1556px 1906px #b65d8e,
    121px 68px #b65d8e, 1676px 1714px #b65d8e, 516px 936px #b65d8e, 1947px 1492px #b65d8e,
    1455px 1519px #b65d8e, 45px 602px #b65d8e, 205px 1039px #b65d8e, 793px 172px #b65d8e,
    1562px 1739px #b65d8e, 1056px 110px #b65d8e, 1512px 379px #b65d8e, 1795px 1621px #b65d8e,
    1848px 607px #b65d8e, 262px 1719px #b65d8e, 477px 991px #b65d8e, 483px 883px #b65d8e,
    1239px 1197px #b65d8e, 1496px 647px #b65d8e, 1649px 25px #b65d8e, 1491px 1946px #b65d8e,
    119px 996px #b65d8e, 179px 1472px #b65d8e, 1341px 808px #b65d8e, 1565px 1700px #b65d8e,
    407px 1544px #b65d8e, 1754px 357px #b65d8e, 1288px 981px #b65d8e, 902px 1997px #b65d8e,
    1755px 1668px #b65d8e, 186px 877px #b65d8e, 1202px 1882px #b65d8e, 461px 1213px #b65d8e,
    1400px 748px #b65d8e, 1969px 1899px #b65d8e, 809px 522px #b65d8e, 514px 1219px #b65d8e,
    374px 275px #b65d8e, 938px 1973px #b65d8e, 357px 552px #b65d8e, 144px 1722px #b65d8e,
    1572px 912px #b65d8e, 402px 1858px #b65d8e, 1544px 1195px #b65d8e, 667px 1257px #b65d8e,
    727px 1496px #b65d8e, 993px 232px #b65d8e, 1772px 313px #b65d8e, 1040px 1590px #b65d8e,
    1204px 1973px #b65d8e, 1268px 79px #b65d8e, 1555px 1048px #b65d8e, 986px 1707px #b65d8e,
    978px 1710px #b65d8e, 713px 360px #b65d8e, 407px 863px #b65d8e, 461px 736px #b65d8e,
    284px 1608px #b65d8e, 103px 430px #b65d8e, 1283px 1319px #b65d8e, 977px 1186px #b65d8e,
    1966px 1516px #b65d8e, 1287px 1129px #b65d8e, 70px 1098px #b65d8e, 1189px 889px #b65d8e,
    1126px 1734px #b65d8e, 309px 1292px #b65d8e, 879px 764px #b65d8e, 65px 473px #b65d8e,
    1003px 1959px #b65d8e, 658px 791px #b65d8e, 402px 1576px #b65d8e, 35px 622px #b65d8e,
    529px 1589px #b65d8e, 164px 666px #b65d8e, 1876px 1290px #b65d8e, 1541px 526px #b65d8e,
    270px 1297px #b65d8e, 440px 865px #b65d8e, 1500px 802px #b65d8e, 182px 1754px #b65d8e,
    1264px 892px #b65d8e, 272px 1249px #b65d8e, 1289px 1535px #b65d8e, 190px 1646px #b65d8e,
    955px 242px #b65d8e, 1456px 1597px #b65d8e, 1727px 1983px #b65d8e, 635px 801px #b65d8e,
    226px 455px #b65d8e, 1396px 1710px #b65d8e, 849px 1863px #b65d8e, 237px 1264px #b65d8e,
    839px 140px #b65d8e, 1122px 735px #b65d8e, 1280px 15px #b65d8e, 1318px 242px #b65d8e,
    1819px 1148px #b65d8e, 333px 1392px #b65d8e, 1949px 553px #b65d8e, 1878px 1332px #b65d8e,
    467px 548px #b65d8e, 1812px 1082px #b65d8e, 1067px 193px #b65d8e, 243px 156px #b65d8e,
    483px 1616px #b65d8e, 1714px 933px #b65d8e, 759px 1800px #b65d8e, 1822px 995px #b65d8e,
    1877px 572px #b65d8e, 581px 1084px #b65d8e, 107px 732px #b65d8e, 642px 1837px #b65d8e,
    166px 1493px #b65d8e, 1555px 198px #b65d8e, 819px 307px #b65d8e, 947px 345px #b65d8e,
    827px 224px #b65d8e, 927px 1394px #b65d8e, 540px 467px #b65d8e, 1093px 405px #b65d8e,
    1140px 927px #b65d8e, 130px 529px #b65d8e, 33px 1980px #b65d8e, 1147px 1663px #b65d8e,
    1616px 1436px #b65d8e, 528px 710px #b65d8e, 798px 1100px #b65d8e, 505px 1480px #b65d8e,
    899px 641px #b65d8e, 1909px 1949px #b65d8e, 1311px 964px #b65d8e, 979px 1301px #b65d8e,
    1393px 969px #b65d8e, 1793px 1886px #b65d8e, 292px 357px #b65d8e, 1196px 1718px #b65d8e,
    1290px 1994px #b65d8e, 537px 1973px #b65d8e, 1181px 1674px #b65d8e,
    1740px 1566px #b65d8e, 1307px 265px #b65d8e, 922px 522px #b65d8e, 1892px 472px #b65d8e,
    384px 1746px #b65d8e, 392px 1098px #b65d8e, 647px 548px #b65d8e, 390px 1498px #b65d8e,
    1246px 138px #b65d8e, 730px 876px #b65d8e, 192px 1472px #b65d8e, 1790px 1789px #b65d8e,
    928px 311px #b65d8e, 1253px 1647px #b65d8e, 747px 1921px #b65d8e, 1561px 1025px #b65d8e,
    1533px 1292px #b65d8e, 1985px 195px #b65d8e, 728px 729px #b65d8e, 1712px 1936px #b65d8e,
    512px 1717px #b65d8e, 1528px 483px #b65d8e, 313px 1642px #b65d8e, 281px 1849px #b65d8e,
    1212px 799px #b65d8e, 435px 1191px #b65d8e, 1422px 611px #b65d8e, 1718px 1964px #b65d8e,
    411px 944px #b65d8e, 210px 636px #b65d8e, 1502px 1295px #b65d8e, 1434px 349px #b65d8e,
    769px 60px #b65d8e, 747px 1053px #b65d8e, 789px 504px #b65d8e, 1436px 1264px #b65d8e,
    1893px 1225px #b65d8e, 1394px 1788px #b65d8e, 1108px 1317px #b65d8e,
    1673px 1395px #b65d8e, 854px 1010px #b65d8e, 1705px 80px #b65d8e, 1858px 148px #b65d8e,
    1729px 344px #b65d8e, 1388px 664px #b65d8e, 895px 406px #b65d8e, 1479px 157px #b65d8e,
    1441px 1157px #b65d8e, 552px 1900px #b65d8e, 516px 364px #b65d8e, 1647px 189px #b65d8e,
    1427px 1071px #b65d8e, 785px 729px #b65d8e, 1080px 1710px #b65d8e, 504px 204px #b65d8e,
    1177px 1622px #b65d8e, 657px 34px #b65d8e, 1296px 1099px #b65d8e, 248px 180px #b65d8e,
    1212px 1568px #b65d8e, 667px 1562px #b65d8e, 695px 841px #b65d8e, 1608px 1247px #b65d8e,
    751px 882px #b65d8e, 87px 167px #b65d8e, 607px 1368px #b65d8e, 1363px 1203px #b65d8e,
    1836px 317px #b65d8e, 1668px 1703px #b65d8e, 830px 1154px #b65d8e, 1721px 1398px #b65d8e,
    1601px 1280px #b65d8e, 976px 874px #b65d8e, 1743px 254px #b65d8e, 1020px 1815px #b65d8e,
    1670px 1766px #b65d8e, 1890px 735px #b65d8e, 1379px 136px #b65d8e, 1864px 695px #b65d8e,
    206px 965px #b65d8e, 1404px 1932px #b65d8e, 1923px 1360px #b65d8e, 247px 682px #b65d8e,
    519px 1708px #b65d8e, 645px 750px #b65d8e, 1164px 1204px #b65d8e, 834px 323px #b65d8e,
    172px 1350px #b65d8e, 213px 972px #b65d8e, 1837px 190px #b65d8e, 285px 1806px #b65d8e,
    1047px 1299px #b65d8e, 1548px 825px #b65d8e, 1730px 324px #b65d8e, 1346px 1909px #b65d8e,
    772px 270px #b65d8e, 345px 1190px #b65d8e, 478px 1433px #b65d8e, 1479px 25px #b65d8e,
    1994px 1830px #b65d8e, 1744px 732px #b65d8e, 20px 1635px #b65d8e, 690px 1795px #b65d8e,
    1594px 569px #b65d8e, 579px 245px #b65d8e, 1398px 733px #b65d8e, 408px 1352px #b65d8e,
    1774px 120px #b65d8e, 1152px 1370px #b65d8e, 1698px 1810px #b65d8e, 710px 1450px #b65d8e,
    665px 286px #b65d8e, 493px 1720px #b65d8e, 786px 5px #b65d8e, 637px 1140px #b65d8e,
    764px 324px #b65d8e, 927px 310px #b65d8e, 938px 1424px #b65d8e, 1884px 744px #b65d8e,
    913px 462px #b65d8e, 1831px 1936px #b65d8e, 1527px 249px #b65d8e, 36px 1381px #b65d8e,
    1597px 581px #b65d8e, 1530px 355px #b65d8e, 949px 459px #b65d8e, 799px 828px #b65d8e,
    242px 1471px #b65d8e, 654px 797px #b65d8e, 796px 594px #b65d8e, 1365px 678px #b65d8e,
    752px 23px #b65d8e, 1630px 541px #b65d8e, 982px 72px #b65d8e, 1733px 1831px #b65d8e,
    21px 412px #b65d8e, 775px 998px #b65d8e, 335px 1945px #b65d8e, 264px 583px #b65d8e,
    158px 1311px #b65d8e, 528px 164px #b65d8e, 1978px 574px #b65d8e, 717px 1203px #b65d8e,
    734px 1591px #b65d8e, 1555px 820px #b65d8e, 16px 1943px #b65d8e, 1625px 1177px #b65d8e,
    1236px 690px #b65d8e, 1585px 1590px #b65d8e, 1737px 1728px #b65d8e, 721px 698px #b65d8e,
    1804px 1186px #b65d8e, 166px 980px #b65d8e, 1850px 230px #b65d8e, 330px 1712px #b65d8e,
    95px 797px #b65d8e, 1948px 1078px #b65d8e, 469px 939px #b65d8e, 1269px 1899px #b65d8e,
    955px 1220px #b65d8e, 1137px 1075px #b65d8e, 312px 1293px #b65d8e, 986px 1762px #b65d8e,
    1103px 1238px #b65d8e, 428px 1993px #b65d8e, 355px 570px #b65d8e, 977px 1836px #b65d8e,
    1395px 1092px #b65d8e, 276px 913px #b65d8e, 1743px 656px #b65d8e, 773px 502px #b65d8e,
    1686px 1322px #b65d8e, 1516px 1945px #b65d8e, 1334px 501px #b65d8e, 266px 156px #b65d8e,
    455px 655px #b65d8e, 798px 72px #b65d8e, 1059px 1259px #b65d8e, 1402px 1687px #b65d8e,
    236px 1329px #b65d8e, 1455px 786px #b65d8e, 146px 1228px #b65d8e, 1851px 823px #b65d8e,
    1062px 100px #b65d8e, 1220px 953px #b65d8e, 20px 1826px #b65d8e, 36px 1063px #b65d8e,
    1525px 338px #b65d8e, 790px 1521px #b65d8e, 741px 1099px #b65d8e, 288px 1489px #b65d8e,
    700px 1060px #b65d8e, 390px 1071px #b65d8e, 411px 1036px #b65d8e, 1853px 1072px #b65d8e,
    1446px 1085px #b65d8e, 1164px 874px #b65d8e, 924px 925px #b65d8e, 291px 271px #b65d8e,
    1257px 1964px #b65d8e, 1580px 1352px #b65d8e, 1507px 1216px #b65d8e, 211px 956px #b65d8e,
    985px 1195px #b65d8e, 975px 1640px #b65d8e, 518px 101px #b65d8e, 663px 1395px #b65d8e,
    914px 532px #b65d8e, 145px 1320px #b65d8e, 69px 1397px #b65d8e, 982px 523px #b65d8e,
    257px 725px #b65d8e, 1599px 831px #b65d8e, 1636px 1513px #b65d8e, 1250px 1158px #b65d8e,
    1132px 604px #b65d8e, 183px 102px #b65d8e, 1057px 318px #b65d8e, 1247px 1835px #b65d8e,
    1983px 1110px #b65d8e, 1077px 1455px #b65d8e, 921px 1770px #b65d8e, 806px 1350px #b65d8e,
    1938px 1992px #b65d8e, 855px 1260px #b65d8e, 902px 1345px #b65d8e, 658px 1908px #b65d8e,
    1845px 679px #b65d8e, 712px 1482px #b65d8e, 595px 950px #b65d8e, 1784px 1992px #b65d8e,
    1847px 1785px #b65d8e, 691px 1004px #b65d8e, 175px 1179px #b65d8e, 1666px 1911px #b65d8e,
    41px 61px #b65d8e, 971px 1080px #b65d8e, 1830px 1450px #b65d8e, 1351px 1518px #b65d8e,
    1257px 99px #b65d8e, 1395px 1498px #b65d8e, 1117px 252px #b65d8e, 1779px 597px #b65d8e,
    1346px 729px #b65d8e, 1108px 1144px #b65d8e, 402px 691px #b65d8e, 72px 496px #b65d8e,
    1673px 1604px #b65d8e, 1497px 974px #b65d8e, 1865px 1664px #b65d8e, 88px 806px #b65d8e,
    918px 77px #b65d8e, 244px 1118px #b65d8e, 256px 1820px #b65d8e, 1851px 1840px #b65d8e,
    605px 1851px #b65d8e, 634px 383px #b65d8e, 865px 37px #b65d8e, 943px 1024px #b65d8e,
    1951px 177px #b65d8e, 1097px 523px #b65d8e, 985px 1700px #b65d8e, 1243px 122px #b65d8e,
    768px 1070px #b65d8e, 468px 194px #b65d8e, 320px 1867px #b65d8e, 1850px 185px #b65d8e,
    380px 1616px #b65d8e, 468px 1294px #b65d8e, 1122px 1743px #b65d8e, 884px 299px #b65d8e,
    1300px 1917px #b65d8e, 1860px 396px #b65d8e, 1270px 990px #b65d8e, 529px 733px #b65d8e,
    1975px 1347px #b65d8e, 1885px 685px #b65d8e, 226px 506px #b65d8e, 651px 878px #b65d8e,
    1323px 680px #b65d8e, 1284px 680px #b65d8e, 238px 1967px #b65d8e, 911px 174px #b65d8e,
    1111px 521px #b65d8e, 1150px 85px #b65d8e, 794px 502px #b65d8e, 484px 1856px #b65d8e,
    1809px 368px #b65d8e, 112px 953px #b65d8e, 590px 1009px #b65d8e, 1655px 311px #b65d8e,
    100px 1026px #b65d8e, 1803px 352px #b65d8e, 865px 306px #b65d8e, 1077px 1019px #b65d8e,
    1335px 872px #b65d8e, 1647px 1298px #b65d8e, 1233px 1387px #b65d8e, 698px 1036px #b65d8e,
    659px 1860px #b65d8e, 388px 1412px #b65d8e, 1212px 458px #b65d8e, 755px 1468px #b65d8e,
    696px 1654px #b65d8e, 1144px 60px #b65d8e;
}

#stars:after {
    content: '';
    position: absolute;
    top: 2000px;
    width: 1px;
    height: 1px;
    background: transparent;
    box-shadow: 779px 1331px #b65d8e, 324px 42px #b65d8e, 303px 586px #b65d8e,
    1312px 276px #b65d8e, 451px 625px #b65d8e, 521px 1931px #b65d8e, 1087px 1871px #b65d8e,
    36px 1546px #b65d8e, 132px 934px #b65d8e, 1698px 901px #b65d8e, 1418px 664px #b65d8e,
    1448px 1157px #b65d8e, 1084px 232px #b65d8e, 347px 1776px #b65d8e, 1722px 243px #b65d8e,
    1629px 835px #b65d8e, 479px 969px #b65d8e, 1231px 960px #b65d8e, 586px 384px #b65d8e,
    164px 527px #b65d8e, 8px 646px #b65d8e, 1150px 1126px #b65d8e, 665px 1357px #b65d8e,
    1556px 1982px #b65d8e, 1260px 1961px #b65d8e, 1675px 1741px #b65d8e,
    1843px 1514px #b65d8e, 718px 1628px #b65d8e, 242px 1343px #b65d8e, 1497px 1880px #b65d8e,
    1364px 230px #b65d8e, 1739px 1302px #b65d8e, 636px 959px #b65d8e, 304px 686px #b65d8e,
    614px 751px #b65d8e, 1935px 816px #b65d8e, 1428px 60px #b65d8e, 355px 335px #b65d8e,
    1594px 158px #b65d8e, 90px 60px #b65d8e, 1553px 162px #b65d8e, 1239px 1825px #b65d8e,
    1945px 587px #b65d8e, 749px 1785px #b65d8e, 1987px 1172px #b65d8e, 1301px 1237px #b65d8e,
    1039px 342px #b65d8e, 1585px 1481px #b65d8e, 995px 1048px #b65d8e, 524px 932px #b65d8e,
    214px 413px #b65d8e, 1701px 1300px #b65d8e, 1037px 1613px #b65d8e, 1871px 996px #b65d8e,
    1360px 1635px #b65d8e, 1110px 1313px #b65d8e, 412px 1783px #b65d8e, 1949px 177px #b65d8e,
    903px 1854px #b65d8e, 700px 1936px #b65d8e, 378px 125px #b65d8e, 308px 834px #b65d8e,
    1118px 962px #b65d8e, 1350px 1929px #b65d8e, 781px 1811px #b65d8e, 561px 137px #b65d8e,
    757px 1148px #b65d8e, 1670px 1979px #b65d8e, 343px 739px #b65d8e, 945px 795px #b65d8e,
    576px 1903px #b65d8e, 1078px 1436px #b65d8e, 1583px 450px #b65d8e, 1366px 474px #b65d8e,
    297px 1873px #b65d8e, 192px 162px #b65d8e, 1624px 1633px #b65d8e, 59px 453px #b65d8e,
    82px 1872px #b65d8e, 1933px 498px #b65d8e, 1966px 1974px #b65d8e, 1975px 1688px #b65d8e,
    779px 314px #b65d8e, 1858px 1543px #b65d8e, 73px 1507px #b65d8e, 1693px 975px #b65d8e,
    1683px 108px #b65d8e, 1768px 1654px #b65d8e, 654px 14px #b65d8e, 494px 171px #b65d8e,
    1689px 1895px #b65d8e, 1660px 263px #b65d8e, 1031px 903px #b65d8e, 1203px 1393px #b65d8e,
    1333px 1421px #b65d8e, 1113px 41px #b65d8e, 1206px 1645px #b65d8e, 1325px 1635px #b65d8e,
    142px 388px #b65d8e, 572px 215px #b65d8e, 1535px 296px #b65d8e, 1419px 407px #b65d8e,
    1379px 1003px #b65d8e, 329px 469px #b65d8e, 1791px 1652px #b65d8e, 935px 1802px #b65d8e,
    1330px 1820px #b65d8e, 421px 1933px #b65d8e, 828px 365px #b65d8e, 275px 316px #b65d8e,
    707px 960px #b65d8e, 1605px 1554px #b65d8e, 625px 58px #b65d8e, 717px 1697px #b65d8e,
    1669px 246px #b65d8e, 1925px 322px #b65d8e, 1154px 1803px #b65d8e, 1929px 295px #b65d8e,
    1248px 240px #b65d8e, 1045px 1755px #b65d8e, 166px 942px #b65d8e, 1888px 1773px #b65d8e,
    678px 1963px #b65d8e, 1370px 569px #b65d8e, 1974px 1400px #b65d8e, 1786px 460px #b65d8e,
    51px 307px #b65d8e, 784px 1400px #b65d8e, 730px 1258px #b65d8e, 1712px 393px #b65d8e,
    416px 170px #b65d8e, 1797px 1932px #b65d8e, 572px 219px #b65d8e, 1557px 1856px #b65d8e,
    218px 8px #b65d8e, 348px 1334px #b65d8e, 469px 413px #b65d8e, 385px 1738px #b65d8e,
    1357px 1818px #b65d8e, 240px 942px #b65d8e, 248px 1847px #b65d8e, 1535px 806px #b65d8e,
    236px 1514px #b65d8e, 1429px 1556px #b65d8e, 73px 1633px #b65d8e, 1398px 1121px #b65d8e,
    671px 1301px #b65d8e, 1404px 1663px #b65d8e, 740px 1018px #b65d8e, 1600px 377px #b65d8e,
    785px 514px #b65d8e, 112px 1084px #b65d8e, 1915px 1887px #b65d8e, 1463px 1848px #b65d8e,
    687px 1115px #b65d8e, 1268px 1768px #b65d8e, 1729px 1425px #b65d8e,
    1284px 1022px #b65d8e, 801px 974px #b65d8e, 1975px 1317px #b65d8e, 1354px 834px #b65d8e,
    1446px 1484px #b65d8e, 1283px 1786px #b65d8e, 11px 523px #b65d8e, 1842px 236px #b65d8e,
    1355px 654px #b65d8e, 429px 7px #b65d8e, 1033px 1128px #b65d8e, 157px 297px #b65d8e,
    545px 635px #b65d8e, 52px 1080px #b65d8e, 827px 1520px #b65d8e, 1121px 490px #b65d8e,
    9px 309px #b65d8e, 1744px 1586px #b65d8e, 1014px 417px #b65d8e, 1534px 524px #b65d8e,
    958px 552px #b65d8e, 1403px 1496px #b65d8e, 387px 703px #b65d8e, 1522px 548px #b65d8e,
    1355px 282px #b65d8e, 1532px 601px #b65d8e, 1838px 790px #b65d8e, 290px 259px #b65d8e,
    295px 598px #b65d8e, 1601px 539px #b65d8e, 1561px 1272px #b65d8e, 34px 1922px #b65d8e,
    1024px 543px #b65d8e, 467px 369px #b65d8e, 722px 333px #b65d8e, 1976px 1255px #b65d8e,
    766px 983px #b65d8e, 1582px 1285px #b65d8e, 12px 512px #b65d8e, 617px 1410px #b65d8e,
    682px 577px #b65d8e, 1334px 1438px #b65d8e, 439px 327px #b65d8e, 1617px 1661px #b65d8e,
    673px 129px #b65d8e, 794px 941px #b65d8e, 1386px 1902px #b65d8e, 37px 1353px #b65d8e,
    1467px 1353px #b65d8e, 416px 18px #b65d8e, 187px 344px #b65d8e, 200px 1898px #b65d8e,
    1491px 1619px #b65d8e, 811px 347px #b65d8e, 924px 1827px #b65d8e, 945px 217px #b65d8e,
    1735px 1228px #b65d8e, 379px 1890px #b65d8e, 79px 761px #b65d8e, 825px 1837px #b65d8e,
    1980px 1558px #b65d8e, 1308px 1573px #b65d8e, 1488px 1726px #b65d8e,
    382px 1208px #b65d8e, 522px 595px #b65d8e, 1277px 1898px #b65d8e, 354px 552px #b65d8e,
    161px 1784px #b65d8e, 614px 251px #b65d8e, 526px 1576px #b65d8e, 17px 212px #b65d8e,
    179px 996px #b65d8e, 467px 1208px #b65d8e, 1944px 1838px #b65d8e, 1140px 1093px #b65d8e,
    858px 1007px #b65d8e, 200px 1064px #b65d8e, 423px 1964px #b65d8e, 1945px 439px #b65d8e,
    1377px 689px #b65d8e, 1120px 1437px #b65d8e, 1876px 668px #b65d8e, 907px 1324px #b65d8e,
    343px 1976px #b65d8e, 1816px 1501px #b65d8e, 1849px 177px #b65d8e, 647px 91px #b65d8e,
    1984px 1012px #b65d8e, 1336px 1300px #b65d8e, 128px 648px #b65d8e, 305px 1060px #b65d8e,
    1324px 826px #b65d8e, 1263px 1314px #b65d8e, 1801px 629px #b65d8e, 1614px 1555px #b65d8e,
    1634px 90px #b65d8e, 1603px 452px #b65d8e, 891px 1984px #b65d8e, 1556px 1906px #b65d8e,
    121px 68px #b65d8e, 1676px 1714px #b65d8e, 516px 936px #b65d8e, 1947px 1492px #b65d8e,
    1455px 1519px #b65d8e, 45px 602px #b65d8e, 205px 1039px #b65d8e, 793px 172px #b65d8e,
    1562px 1739px #b65d8e, 1056px 110px #b65d8e, 1512px 379px #b65d8e, 1795px 1621px #b65d8e,
    1848px 607px #b65d8e, 262px 1719px #b65d8e, 477px 991px #b65d8e, 483px 883px #b65d8e,
    1239px 1197px #b65d8e, 1496px 647px #b65d8e, 1649px 25px #b65d8e, 1491px 1946px #b65d8e,
    119px 996px #b65d8e, 179px 1472px #b65d8e, 1341px 808px #b65d8e, 1565px 1700px #b65d8e,
    407px 1544px #b65d8e, 1754px 357px #b65d8e, 1288px 981px #b65d8e, 902px 1997px #b65d8e,
    1755px 1668px #b65d8e, 186px 877px #b65d8e, 1202px 1882px #b65d8e, 461px 1213px #b65d8e,
    1400px 748px #b65d8e, 1969px 1899px #b65d8e, 809px 522px #b65d8e, 514px 1219px #b65d8e,
    374px 275px #b65d8e, 938px 1973px #b65d8e, 357px 552px #b65d8e, 144px 1722px #b65d8e,
    1572px 912px #b65d8e, 402px 1858px #b65d8e, 1544px 1195px #b65d8e, 667px 1257px #b65d8e,
    727px 1496px #b65d8e, 993px 232px #b65d8e, 1772px 313px #b65d8e, 1040px 1590px #b65d8e,
    1204px 1973px #b65d8e, 1268px 79px #b65d8e, 1555px 1048px #b65d8e, 986px 1707px #b65d8e,
    978px 1710px #b65d8e, 713px 360px #b65d8e, 407px 863px #b65d8e, 461px 736px #b65d8e,
    284px 1608px #b65d8e, 103px 430px #b65d8e, 1283px 1319px #b65d8e, 977px 1186px #b65d8e,
    1966px 1516px #b65d8e, 1287px 1129px #b65d8e, 70px 1098px #b65d8e, 1189px 889px #b65d8e,
    1126px 1734px #b65d8e, 309px 1292px #b65d8e, 879px 764px #b65d8e, 65px 473px #b65d8e,
    1003px 1959px #b65d8e, 658px 791px #b65d8e, 402px 1576px #b65d8e, 35px 622px #b65d8e,
    529px 1589px #b65d8e, 164px 666px #b65d8e, 1876px 1290px #b65d8e, 1541px 526px #b65d8e,
    270px 1297px #b65d8e, 440px 865px #b65d8e, 1500px 802px #b65d8e, 182px 1754px #b65d8e,
    1264px 892px #b65d8e, 272px 1249px #b65d8e, 1289px 1535px #b65d8e, 190px 1646px #b65d8e,
    955px 242px #b65d8e, 1456px 1597px #b65d8e, 1727px 1983px #b65d8e, 635px 801px #b65d8e,
    226px 455px #b65d8e, 1396px 1710px #b65d8e, 849px 1863px #b65d8e, 237px 1264px #b65d8e,
    839px 140px #b65d8e, 1122px 735px #b65d8e, 1280px 15px #b65d8e, 1318px 242px #b65d8e,
    1819px 1148px #b65d8e, 333px 1392px #b65d8e, 1949px 553px #b65d8e, 1878px 1332px #b65d8e,
    467px 548px #b65d8e, 1812px 1082px #b65d8e, 1067px 193px #b65d8e, 243px 156px #b65d8e,
    483px 1616px #b65d8e, 1714px 933px #b65d8e, 759px 1800px #b65d8e, 1822px 995px #b65d8e,
    1877px 572px #b65d8e, 581px 1084px #b65d8e, 107px 732px #b65d8e, 642px 1837px #b65d8e,
    166px 1493px #b65d8e, 1555px 198px #b65d8e, 819px 307px #b65d8e, 947px 345px #b65d8e,
    827px 224px #b65d8e, 927px 1394px #b65d8e, 540px 467px #b65d8e, 1093px 405px #b65d8e,
    1140px 927px #b65d8e, 130px 529px #b65d8e, 33px 1980px #b65d8e, 1147px 1663px #b65d8e,
    1616px 1436px #b65d8e, 528px 710px #b65d8e, 798px 1100px #b65d8e, 505px 1480px #b65d8e,
    899px 641px #b65d8e, 1909px 1949px #b65d8e, 1311px 964px #b65d8e, 979px 1301px #b65d8e,
    1393px 969px #b65d8e, 1793px 1886px #b65d8e, 292px 357px #b65d8e, 1196px 1718px #b65d8e,
    1290px 1994px #b65d8e, 537px 1973px #b65d8e, 1181px 1674px #b65d8e,
    1740px 1566px #b65d8e, 1307px 265px #b65d8e, 922px 522px #b65d8e, 1892px 472px #b65d8e,
    384px 1746px #b65d8e, 392px 1098px #b65d8e, 647px 548px #b65d8e, 390px 1498px #b65d8e,
    1246px 138px #b65d8e, 730px 876px #b65d8e, 192px 1472px #b65d8e, 1790px 1789px #b65d8e,
    928px 311px #b65d8e, 1253px 1647px #b65d8e, 747px 1921px #b65d8e, 1561px 1025px #b65d8e,
    1533px 1292px #b65d8e, 1985px 195px #b65d8e, 728px 729px #b65d8e, 1712px 1936px #b65d8e,
    512px 1717px #b65d8e, 1528px 483px #b65d8e, 313px 1642px #b65d8e, 281px 1849px #b65d8e,
    1212px 799px #b65d8e, 435px 1191px #b65d8e, 1422px 611px #b65d8e, 1718px 1964px #b65d8e,
    411px 944px #b65d8e, 210px 636px #b65d8e, 1502px 1295px #b65d8e, 1434px 349px #b65d8e,
    769px 60px #b65d8e, 747px 1053px #b65d8e, 789px 504px #b65d8e, 1436px 1264px #b65d8e,
    1893px 1225px #b65d8e, 1394px 1788px #b65d8e, 1108px 1317px #b65d8e,
    1673px 1395px #b65d8e, 854px 1010px #b65d8e, 1705px 80px #b65d8e, 1858px 148px #b65d8e,
    1729px 344px #b65d8e, 1388px 664px #b65d8e, 895px 406px #b65d8e, 1479px 157px #b65d8e,
    1441px 1157px #b65d8e, 552px 1900px #b65d8e, 516px 364px #b65d8e, 1647px 189px #b65d8e,
    1427px 1071px #b65d8e, 785px 729px #b65d8e, 1080px 1710px #b65d8e, 504px 204px #b65d8e,
    1177px 1622px #b65d8e, 657px 34px #b65d8e, 1296px 1099px #b65d8e, 248px 180px #b65d8e,
    1212px 1568px #b65d8e, 667px 1562px #b65d8e, 695px 841px #b65d8e, 1608px 1247px #b65d8e,
    751px 882px #b65d8e, 87px 167px #b65d8e, 607px 1368px #b65d8e, 1363px 1203px #b65d8e,
    1836px 317px #b65d8e, 1668px 1703px #b65d8e, 830px 1154px #b65d8e, 1721px 1398px #b65d8e,
    1601px 1280px #b65d8e, 976px 874px #b65d8e, 1743px 254px #b65d8e, 1020px 1815px #b65d8e,
    1670px 1766px #b65d8e, 1890px 735px #b65d8e, 1379px 136px #b65d8e, 1864px 695px #b65d8e,
    206px 965px #b65d8e, 1404px 1932px #b65d8e, 1923px 1360px #b65d8e, 247px 682px #b65d8e,
    519px 1708px #b65d8e, 645px 750px #b65d8e, 1164px 1204px #b65d8e, 834px 323px #b65d8e,
    172px 1350px #b65d8e, 213px 972px #b65d8e, 1837px 190px #b65d8e, 285px 1806px #b65d8e,
    1047px 1299px #b65d8e, 1548px 825px #b65d8e, 1730px 324px #b65d8e, 1346px 1909px #b65d8e,
    772px 270px #b65d8e, 345px 1190px #b65d8e, 478px 1433px #b65d8e, 1479px 25px #b65d8e,
    1994px 1830px #b65d8e, 1744px 732px #b65d8e, 20px 1635px #b65d8e, 690px 1795px #b65d8e,
    1594px 569px #b65d8e, 579px 245px #b65d8e, 1398px 733px #b65d8e, 408px 1352px #b65d8e,
    1774px 120px #b65d8e, 1152px 1370px #b65d8e, 1698px 1810px #b65d8e, 710px 1450px #b65d8e,
    665px 286px #b65d8e, 493px 1720px #b65d8e, 786px 5px #b65d8e, 637px 1140px #b65d8e,
    764px 324px #b65d8e, 927px 310px #b65d8e, 938px 1424px #b65d8e, 1884px 744px #b65d8e,
    913px 462px #b65d8e, 1831px 1936px #b65d8e, 1527px 249px #b65d8e, 36px 1381px #b65d8e,
    1597px 581px #b65d8e, 1530px 355px #b65d8e, 949px 459px #b65d8e, 799px 828px #b65d8e,
    242px 1471px #b65d8e, 654px 797px #b65d8e, 796px 594px #b65d8e, 1365px 678px #b65d8e,
    752px 23px #b65d8e, 1630px 541px #b65d8e, 982px 72px #b65d8e, 1733px 1831px #b65d8e,
    21px 412px #b65d8e, 775px 998px #b65d8e, 335px 1945px #b65d8e, 264px 583px #b65d8e,
    158px 1311px #b65d8e, 528px 164px #b65d8e, 1978px 574px #b65d8e, 717px 1203px #b65d8e,
    734px 1591px #b65d8e, 1555px 820px #b65d8e, 16px 1943px #b65d8e, 1625px 1177px #b65d8e,
    1236px 690px #b65d8e, 1585px 1590px #b65d8e, 1737px 1728px #b65d8e, 721px 698px #b65d8e,
    1804px 1186px #b65d8e, 166px 980px #b65d8e, 1850px 230px #b65d8e, 330px 1712px #b65d8e,
    95px 797px #b65d8e, 1948px 1078px #b65d8e, 469px 939px #b65d8e, 1269px 1899px #b65d8e,
    955px 1220px #b65d8e, 1137px 1075px #b65d8e, 312px 1293px #b65d8e, 986px 1762px #b65d8e,
    1103px 1238px #b65d8e, 428px 1993px #b65d8e, 355px 570px #b65d8e, 977px 1836px #b65d8e,
    1395px 1092px #b65d8e, 276px 913px #b65d8e, 1743px 656px #b65d8e, 773px 502px #b65d8e,
    1686px 1322px #b65d8e, 1516px 1945px #b65d8e, 1334px 501px #b65d8e, 266px 156px #b65d8e,
    455px 655px #b65d8e, 798px 72px #b65d8e, 1059px 1259px #b65d8e, 1402px 1687px #b65d8e,
    236px 1329px #b65d8e, 1455px 786px #b65d8e, 146px 1228px #b65d8e, 1851px 823px #b65d8e,
    1062px 100px #b65d8e, 1220px 953px #b65d8e, 20px 1826px #b65d8e, 36px 1063px #b65d8e,
    1525px 338px #b65d8e, 790px 1521px #b65d8e, 741px 1099px #b65d8e, 288px 1489px #b65d8e,
    700px 1060px #b65d8e, 390px 1071px #b65d8e, 411px 1036px #b65d8e, 1853px 1072px #b65d8e,
    1446px 1085px #b65d8e, 1164px 874px #b65d8e, 924px 925px #b65d8e, 291px 271px #b65d8e,
    1257px 1964px #b65d8e, 1580px 1352px #b65d8e, 1507px 1216px #b65d8e, 211px 956px #b65d8e,
    985px 1195px #b65d8e, 975px 1640px #b65d8e, 518px 101px #b65d8e, 663px 1395px #b65d8e,
    914px 532px #b65d8e, 145px 1320px #b65d8e, 69px 1397px #b65d8e, 982px 523px #b65d8e,
    257px 725px #b65d8e, 1599px 831px #b65d8e, 1636px 1513px #b65d8e, 1250px 1158px #b65d8e,
    1132px 604px #b65d8e, 183px 102px #b65d8e, 1057px 318px #b65d8e, 1247px 1835px #b65d8e,
    1983px 1110px #b65d8e, 1077px 1455px #b65d8e, 921px 1770px #b65d8e, 806px 1350px #b65d8e,
    1938px 1992px #b65d8e, 855px 1260px #b65d8e, 902px 1345px #b65d8e, 658px 1908px #b65d8e,
    1845px 679px #b65d8e, 712px 1482px #b65d8e, 595px 950px #b65d8e, 1784px 1992px #b65d8e,
    1847px 1785px #b65d8e, 691px 1004px #b65d8e, 175px 1179px #b65d8e, 1666px 1911px #b65d8e,
    41px 61px #b65d8e, 971px 1080px #b65d8e, 1830px 1450px #b65d8e, 1351px 1518px #b65d8e,
    1257px 99px #b65d8e, 1395px 1498px #b65d8e, 1117px 252px #b65d8e, 1779px 597px #b65d8e,
    1346px 729px #b65d8e, 1108px 1144px #b65d8e, 402px 691px #b65d8e, 72px 496px #b65d8e,
    1673px 1604px #b65d8e, 1497px 974px #b65d8e, 1865px 1664px #b65d8e, 88px 806px #b65d8e,
    918px 77px #b65d8e, 244px 1118px #b65d8e, 256px 1820px #b65d8e, 1851px 1840px #b65d8e,
    605px 1851px #b65d8e, 634px 383px #b65d8e, 865px 37px #b65d8e, 943px 1024px #b65d8e,
    1951px 177px #b65d8e, 1097px 523px #b65d8e, 985px 1700px #b65d8e, 1243px 122px #b65d8e,
    768px 1070px #b65d8e, 468px 194px #b65d8e, 320px 1867px #b65d8e, 1850px 185px #b65d8e,
    380px 1616px #b65d8e, 468px 1294px #b65d8e, 1122px 1743px #b65d8e, 884px 299px #b65d8e,
    1300px 1917px #b65d8e, 1860px 396px #b65d8e, 1270px 990px #b65d8e, 529px 733px #b65d8e,
    1975px 1347px #b65d8e, 1885px 685px #b65d8e, 226px 506px #b65d8e, 651px 878px #b65d8e,
    1323px 680px #b65d8e, 1284px 680px #b65d8e, 238px 1967px #b65d8e, 911px 174px #b65d8e,
    1111px 521px #b65d8e, 1150px 85px #b65d8e, 794px 502px #b65d8e, 484px 1856px #b65d8e,
    1809px 368px #b65d8e, 112px 953px #b65d8e, 590px 1009px #b65d8e, 1655px 311px #b65d8e,
    100px 1026px #b65d8e, 1803px 352px #b65d8e, 865px 306px #b65d8e, 1077px 1019px #b65d8e,
    1335px 872px #b65d8e, 1647px 1298px #b65d8e, 1233px 1387px #b65d8e, 698px 1036px #b65d8e,
    659px 1860px #b65d8e, 388px 1412px #b65d8e, 1212px 458px #b65d8e, 755px 1468px #b65d8e,
    696px 1654px #b65d8e, 1144px 60px #b65d8e;
}

#stars2 {
    width: 2px;
    height: 2px;
    background: transparent;
    animation: animStar 100s linear infinite;
    box-shadow: 1448px 320px #b65d8e, 1775px 1663px #b65d8e, 332px 1364px #b65d8e,
    878px 340px #b65d8e, 569px 1832px #b65d8e, 1422px 1684px #b65d8e, 1946px 1907px #b65d8e,
    121px 979px #b65d8e, 1044px 1069px #b65d8e, 463px 381px #b65d8e, 423px 112px #b65d8e,
    523px 1179px #b65d8e, 779px 654px #b65d8e, 1398px 694px #b65d8e, 1085px 1464px #b65d8e,
    1599px 1869px #b65d8e, 801px 1882px #b65d8e, 779px 1231px #b65d8e, 552px 932px #b65d8e,
    1057px 1196px #b65d8e, 282px 1280px #b65d8e, 496px 1986px #b65d8e, 1833px 1120px #b65d8e,
    1802px 1293px #b65d8e, 6px 1696px #b65d8e, 412px 1902px #b65d8e, 605px 438px #b65d8e,
    24px 1212px #b65d8e, 234px 1320px #b65d8e, 544px 344px #b65d8e, 1107px 170px #b65d8e,
    1603px 196px #b65d8e, 905px 648px #b65d8e, 68px 1458px #b65d8e, 649px 1969px #b65d8e,
    744px 675px #b65d8e, 1127px 478px #b65d8e, 714px 1814px #b65d8e, 1486px 526px #b65d8e,
    270px 1636px #b65d8e, 1931px 149px #b65d8e, 1807px 378px #b65d8e, 8px 390px #b65d8e,
    1415px 699px #b65d8e, 1473px 1211px #b65d8e, 1590px 141px #b65d8e, 270px 1705px #b65d8e,
    69px 1423px #b65d8e, 1108px 1053px #b65d8e, 1946px 128px #b65d8e, 371px 371px #b65d8e,
    1490px 220px #b65d8e, 357px 1885px #b65d8e, 363px 363px #b65d8e, 1896px 1256px #b65d8e,
    1979px 1050px #b65d8e, 947px 1342px #b65d8e, 1754px 242px #b65d8e, 514px 974px #b65d8e,
    65px 1477px #b65d8e, 1840px 547px #b65d8e, 950px 695px #b65d8e, 459px 1150px #b65d8e,
    1124px 1502px #b65d8e, 481px 940px #b65d8e, 680px 839px #b65d8e, 797px 1169px #b65d8e,
    1977px 1491px #b65d8e, 734px 1724px #b65d8e, 210px 298px #b65d8e, 816px 628px #b65d8e,
    686px 770px #b65d8e, 1721px 267px #b65d8e, 1663px 511px #b65d8e, 1481px 1141px #b65d8e,
    582px 248px #b65d8e, 1308px 953px #b65d8e, 628px 657px #b65d8e, 897px 1535px #b65d8e,
    270px 931px #b65d8e, 791px 467px #b65d8e, 1336px 1732px #b65d8e, 1013px 1653px #b65d8e,
    1911px 956px #b65d8e, 587px 816px #b65d8e, 83px 456px #b65d8e, 930px 1478px #b65d8e,
    1587px 1694px #b65d8e, 614px 1200px #b65d8e, 302px 1782px #b65d8e, 1711px 1432px #b65d8e,
    443px 904px #b65d8e, 1666px 714px #b65d8e, 1588px 1167px #b65d8e, 273px 1075px #b65d8e,
    1679px 461px #b65d8e, 721px 664px #b65d8e, 1202px 10px #b65d8e, 166px 1126px #b65d8e,
    331px 1628px #b65d8e, 430px 1565px #b65d8e, 1585px 509px #b65d8e, 640px 38px #b65d8e,
    822px 837px #b65d8e, 1760px 1664px #b65d8e, 1122px 1458px #b65d8e, 398px 131px #b65d8e,
    689px 285px #b65d8e, 460px 652px #b65d8e, 1627px 365px #b65d8e, 348px 1648px #b65d8e,
    819px 1946px #b65d8e, 981px 1917px #b65d8e, 323px 76px #b65d8e, 979px 684px #b65d8e,
    887px 536px #b65d8e, 1348px 1596px #b65d8e, 1055px 666px #b65d8e, 1402px 1797px #b65d8e,
    1300px 1055px #b65d8e, 937px 238px #b65d8e, 1474px 1815px #b65d8e, 1144px 1710px #b65d8e,
    1629px 1087px #b65d8e, 911px 919px #b65d8e, 771px 819px #b65d8e, 403px 720px #b65d8e,
    163px 736px #b65d8e, 1062px 238px #b65d8e, 1774px 818px #b65d8e, 1874px 1178px #b65d8e,
    1177px 699px #b65d8e, 1244px 1244px #b65d8e, 1371px 58px #b65d8e, 564px 1515px #b65d8e,
    1824px 487px #b65d8e, 929px 702px #b65d8e, 394px 1348px #b65d8e, 1161px 641px #b65d8e,
    219px 1841px #b65d8e, 358px 941px #b65d8e, 140px 1759px #b65d8e, 1019px 1345px #b65d8e,
    274px 436px #b65d8e, 1433px 1605px #b65d8e, 1798px 1426px #b65d8e, 294px 1848px #b65d8e,
    1681px 1877px #b65d8e, 1344px 1824px #b65d8e, 1439px 1632px #b65d8e,
    161px 1012px #b65d8e, 1308px 588px #b65d8e, 1789px 582px #b65d8e, 721px 1910px #b65d8e,
    318px 218px #b65d8e, 607px 319px #b65d8e, 495px 535px #b65d8e, 1552px 1575px #b65d8e,
    1562px 67px #b65d8e, 403px 926px #b65d8e, 1096px 1800px #b65d8e, 1814px 1709px #b65d8e,
    1882px 1831px #b65d8e, 533px 46px #b65d8e, 823px 969px #b65d8e, 530px 165px #b65d8e,
    1030px 352px #b65d8e, 1681px 313px #b65d8e, 338px 115px #b65d8e, 1607px 211px #b65d8e,
    1718px 1184px #b65d8e, 1589px 659px #b65d8e, 278px 355px #b65d8e, 464px 1464px #b65d8e,
    1165px 277px #b65d8e, 950px 694px #b65d8e, 1746px 293px #b65d8e, 793px 911px #b65d8e,
    528px 773px #b65d8e, 1883px 1694px #b65d8e, 748px 182px #b65d8e, 1924px 1531px #b65d8e,
    100px 636px #b65d8e, 1473px 1445px #b65d8e, 1264px 1244px #b65d8e, 850px 1377px #b65d8e,
    987px 1976px #b65d8e, 933px 1761px #b65d8e, 922px 1270px #b65d8e, 500px 396px #b65d8e,
    1324px 8px #b65d8e, 1967px 1814px #b65d8e, 1072px 1401px #b65d8e, 961px 37px #b65d8e,
    156px 81px #b65d8e, 1915px 502px #b65d8e, 1076px 1846px #b65d8e, 152px 1669px #b65d8e,
    986px 1529px #b65d8e, 1667px 1137px #b65d8e;
}

#stars2:after {
    content: '';
    position: absolute;
    top: 2000px;
    width: 2px;
    height: 2px;
    background: transparent;
    box-shadow: 1448px 320px #b65d8e, 1775px 1663px #b65d8e, 332px 1364px #b65d8e,
    878px 340px #b65d8e, 569px 1832px #b65d8e, 1422px 1684px #b65d8e, 1946px 1907px #b65d8e,
    121px 979px #b65d8e, 1044px 1069px #b65d8e, 463px 381px #b65d8e, 423px 112px #b65d8e,
    523px 1179px #b65d8e, 779px 654px #b65d8e, 1398px 694px #b65d8e, 1085px 1464px #b65d8e,
    1599px 1869px #b65d8e, 801px 1882px #b65d8e, 779px 1231px #b65d8e, 552px 932px #b65d8e,
    1057px 1196px #b65d8e, 282px 1280px #b65d8e, 496px 1986px #b65d8e, 1833px 1120px #b65d8e,
    1802px 1293px #b65d8e, 6px 1696px #b65d8e, 412px 1902px #b65d8e, 605px 438px #b65d8e,
    24px 1212px #b65d8e, 234px 1320px #b65d8e, 544px 344px #b65d8e, 1107px 170px #b65d8e,
    1603px 196px #b65d8e, 905px 648px #b65d8e, 68px 1458px #b65d8e, 649px 1969px #b65d8e,
    744px 675px #b65d8e, 1127px 478px #b65d8e, 714px 1814px #b65d8e, 1486px 526px #b65d8e,
    270px 1636px #b65d8e, 1931px 149px #b65d8e, 1807px 378px #b65d8e, 8px 390px #b65d8e,
    1415px 699px #b65d8e, 1473px 1211px #b65d8e, 1590px 141px #b65d8e, 270px 1705px #b65d8e,
    69px 1423px #b65d8e, 1108px 1053px #b65d8e, 1946px 128px #b65d8e, 371px 371px #b65d8e,
    1490px 220px #b65d8e, 357px 1885px #b65d8e, 363px 363px #b65d8e, 1896px 1256px #b65d8e,
    1979px 1050px #b65d8e, 947px 1342px #b65d8e, 1754px 242px #b65d8e, 514px 974px #b65d8e,
    65px 1477px #b65d8e, 1840px 547px #b65d8e, 950px 695px #b65d8e, 459px 1150px #b65d8e,
    1124px 1502px #b65d8e, 481px 940px #b65d8e, 680px 839px #b65d8e, 797px 1169px #b65d8e,
    1977px 1491px #b65d8e, 734px 1724px #b65d8e, 210px 298px #b65d8e, 816px 628px #b65d8e,
    686px 770px #b65d8e, 1721px 267px #b65d8e, 1663px 511px #b65d8e, 1481px 1141px #b65d8e,
    582px 248px #b65d8e, 1308px 953px #b65d8e, 628px 657px #b65d8e, 897px 1535px #b65d8e,
    270px 931px #b65d8e, 791px 467px #b65d8e, 1336px 1732px #b65d8e, 1013px 1653px #b65d8e,
    1911px 956px #b65d8e, 587px 816px #b65d8e, 83px 456px #b65d8e, 930px 1478px #b65d8e,
    1587px 1694px #b65d8e, 614px 1200px #b65d8e, 302px 1782px #b65d8e, 1711px 1432px #b65d8e,
    443px 904px #b65d8e, 1666px 714px #b65d8e, 1588px 1167px #b65d8e, 273px 1075px #b65d8e,
    1679px 461px #b65d8e, 721px 664px #b65d8e, 1202px 10px #b65d8e, 166px 1126px #b65d8e,
    331px 1628px #b65d8e, 430px 1565px #b65d8e, 1585px 509px #b65d8e, 640px 38px #b65d8e,
    822px 837px #b65d8e, 1760px 1664px #b65d8e, 1122px 1458px #b65d8e, 398px 131px #b65d8e,
    689px 285px #b65d8e, 460px 652px #b65d8e, 1627px 365px #b65d8e, 348px 1648px #b65d8e,
    819px 1946px #b65d8e, 981px 1917px #b65d8e, 323px 76px #b65d8e, 979px 684px #b65d8e,
    887px 536px #b65d8e, 1348px 1596px #b65d8e, 1055px 666px #b65d8e, 1402px 1797px #b65d8e,
    1300px 1055px #b65d8e, 937px 238px #b65d8e, 1474px 1815px #b65d8e, 1144px 1710px #b65d8e,
    1629px 1087px #b65d8e, 911px 919px #b65d8e, 771px 819px #b65d8e, 403px 720px #b65d8e,
    163px 736px #b65d8e, 1062px 238px #b65d8e, 1774px 818px #b65d8e, 1874px 1178px #b65d8e,
    1177px 699px #b65d8e, 1244px 1244px #b65d8e, 1371px 58px #b65d8e, 564px 1515px #b65d8e,
    1824px 487px #b65d8e, 929px 702px #b65d8e, 394px 1348px #b65d8e, 1161px 641px #b65d8e,
    219px 1841px #b65d8e, 358px 941px #b65d8e, 140px 1759px #b65d8e, 1019px 1345px #b65d8e,
    274px 436px #b65d8e, 1433px 1605px #b65d8e, 1798px 1426px #b65d8e, 294px 1848px #b65d8e,
    1681px 1877px #b65d8e, 1344px 1824px #b65d8e, 1439px 1632px #b65d8e,
    161px 1012px #b65d8e, 1308px 588px #b65d8e, 1789px 582px #b65d8e, 721px 1910px #b65d8e,
    318px 218px #b65d8e, 607px 319px #b65d8e, 495px 535px #b65d8e, 1552px 1575px #b65d8e,
    1562px 67px #b65d8e, 403px 926px #b65d8e, 1096px 1800px #b65d8e, 1814px 1709px #b65d8e,
    1882px 1831px #b65d8e, 533px 46px #b65d8e, 823px 969px #b65d8e, 530px 165px #b65d8e,
    1030px 352px #b65d8e, 1681px 313px #b65d8e, 338px 115px #b65d8e, 1607px 211px #b65d8e,
    1718px 1184px #b65d8e, 1589px 659px #b65d8e, 278px 355px #b65d8e, 464px 1464px #b65d8e,
    1165px 277px #b65d8e, 950px 694px #b65d8e, 1746px 293px #b65d8e, 793px 911px #b65d8e,
    528px 773px #b65d8e, 1883px 1694px #b65d8e, 748px 182px #b65d8e, 1924px 1531px #b65d8e,
    100px 636px #b65d8e, 1473px 1445px #b65d8e, 1264px 1244px #b65d8e, 850px 1377px #b65d8e,
    987px 1976px #b65d8e, 933px 1761px #b65d8e, 922px 1270px #b65d8e, 500px 396px #b65d8e,
    1324px 8px #b65d8e, 1967px 1814px #b65d8e, 1072px 1401px #b65d8e, 961px 37px #b65d8e,
    156px 81px #b65d8e, 1915px 502px #b65d8e, 1076px 1846px #b65d8e, 152px 1669px #b65d8e,
    986px 1529px #b65d8e, 1667px 1137px #b65d8e;
}

#stars3 {
    width: 3px;
    height: 3px;
    background: transparent;
    animation: animStar 150s linear infinte;
    box-shadow: 387px 1878px #b65d8e, 760px 1564px #b65d8e, 1487px 999px #b65d8e,
    948px 1828px #b65d8e, 1977px 1001px #b65d8e, 1284px 1963px #b65d8e, 656px 284px #b65d8e,
    1268px 1635px #b65d8e, 1820px 598px #b65d8e, 642px 1900px #b65d8e, 296px 57px #b65d8e,
    921px 1620px #b65d8e, 476px 1858px #b65d8e, 658px 613px #b65d8e, 1171px 1363px #b65d8e,
    1419px 283px #b65d8e, 1037px 731px #b65d8e, 503px 663px #b65d8e, 1562px 463px #b65d8e,
    383px 1197px #b65d8e, 1171px 1233px #b65d8e, 876px 1768px #b65d8e, 856px 1615px #b65d8e,
    1375px 1924px #b65d8e, 1725px 918px #b65d8e, 952px 119px #b65d8e, 768px 1212px #b65d8e,
    992px 1462px #b65d8e, 1929px 717px #b65d8e, 1947px 755px #b65d8e, 1818px 1123px #b65d8e,
    1896px 1672px #b65d8e, 460px 198px #b65d8e, 256px 271px #b65d8e, 752px 544px #b65d8e,
    1222px 1859px #b65d8e, 1851px 443px #b65d8e, 313px 1858px #b65d8e, 709px 446px #b65d8e,
    1546px 697px #b65d8e, 674px 1155px #b65d8e, 1112px 130px #b65d8e, 355px 1790px #b65d8e,
    1496px 974px #b65d8e, 1696px 480px #b65d8e, 1316px 1265px #b65d8e, 1645px 1063px #b65d8e,
    1182px 237px #b65d8e, 427px 1582px #b65d8e, 859px 253px #b65d8e, 458px 939px #b65d8e,
    1517px 1644px #b65d8e, 1943px 60px #b65d8e, 212px 1650px #b65d8e, 966px 1786px #b65d8e,
    473px 712px #b65d8e, 130px 76px #b65d8e, 1417px 1186px #b65d8e, 909px 1580px #b65d8e,
    1913px 762px #b65d8e, 204px 1143px #b65d8e, 1998px 1057px #b65d8e, 1468px 1301px #b65d8e,
    144px 1676px #b65d8e, 21px 1601px #b65d8e, 382px 1362px #b65d8e, 912px 753px #b65d8e,
    1488px 1405px #b65d8e, 802px 156px #b65d8e, 174px 550px #b65d8e, 338px 1366px #b65d8e,
    1197px 774px #b65d8e, 602px 486px #b65d8e, 682px 1877px #b65d8e, 348px 1503px #b65d8e,
    407px 1139px #b65d8e, 950px 1400px #b65d8e, 922px 1139px #b65d8e, 1697px 293px #b65d8e,
    1238px 1281px #b65d8e, 1038px 1197px #b65d8e, 376px 1889px #b65d8e,
    1255px 1680px #b65d8e, 1008px 1316px #b65d8e, 1538px 1447px #b65d8e,
    1186px 874px #b65d8e, 1967px 640px #b65d8e, 1341px 19px #b65d8e, 29px 1732px #b65d8e,
    16px 1650px #b65d8e, 1021px 1075px #b65d8e, 723px 424px #b65d8e, 1175px 41px #b65d8e,
    494px 1957px #b65d8e, 1296px 431px #b65d8e, 175px 1507px #b65d8e, 831px 121px #b65d8e,
    498px 1947px #b65d8e, 617px 880px #b65d8e, 240px 403px #b65d8e;
}

#stars3:after {
    content: '';
    position: absolute;
    top: 2000px;
    width: 3px;
    height: 3px;
    background: transparent;
    box-shadow: 387px 1878px #b65d8e, 760px 1564px #b65d8e, 1487px 999px #b65d8e,
    948px 1828px #b65d8e, 1977px 1001px #b65d8e, 1284px 1963px #b65d8e, 656px 284px #b65d8e,
    1268px 1635px #b65d8e, 1820px 598px #b65d8e, 642px 1900px #b65d8e, 296px 57px #b65d8e,
    921px 1620px #b65d8e, 476px 1858px #b65d8e, 658px 613px #b65d8e, 1171px 1363px #b65d8e,
    1419px 283px #b65d8e, 1037px 731px #b65d8e, 503px 663px #b65d8e, 1562px 463px #b65d8e,
    383px 1197px #b65d8e, 1171px 1233px #b65d8e, 876px 1768px #b65d8e, 856px 1615px #b65d8e,
    1375px 1924px #b65d8e, 1725px 918px #b65d8e, 952px 119px #b65d8e, 768px 1212px #b65d8e,
    992px 1462px #b65d8e, 1929px 717px #b65d8e, 1947px 755px #b65d8e, 1818px 1123px #b65d8e,
    1896px 1672px #b65d8e, 460px 198px #b65d8e, 256px 271px #b65d8e, 752px 544px #b65d8e,
    1222px 1859px #b65d8e, 1851px 443px #b65d8e, 313px 1858px #b65d8e, 709px 446px #b65d8e,
    1546px 697px #b65d8e, 674px 1155px #b65d8e, 1112px 130px #b65d8e, 355px 1790px #b65d8e,
    1496px 974px #b65d8e, 1696px 480px #b65d8e, 1316px 1265px #b65d8e, 1645px 1063px #b65d8e,
    1182px 237px #b65d8e, 427px 1582px #b65d8e, 859px 253px #b65d8e, 458px 939px #b65d8e,
    1517px 1644px #b65d8e, 1943px 60px #b65d8e, 212px 1650px #b65d8e, 966px 1786px #b65d8e,
    473px 712px #b65d8e, 130px 76px #b65d8e, 1417px 1186px #b65d8e, 909px 1580px #b65d8e,
    1913px 762px #b65d8e, 204px 1143px #b65d8e, 1998px 1057px #b65d8e, 1468px 1301px #b65d8e,
    144px 1676px #b65d8e, 21px 1601px #b65d8e, 382px 1362px #b65d8e, 912px 753px #b65d8e,
    1488px 1405px #b65d8e, 802px 156px #b65d8e, 174px 550px #b65d8e, 338px 1366px #b65d8e,
    1197px 774px #b65d8e, 602px 486px #b65d8e, 682px 1877px #b65d8e, 348px 1503px #b65d8e,
    407px 1139px #b65d8e, 950px 1400px #b65d8e, 922px 1139px #b65d8e, 1697px 293px #b65d8e,
    1238px 1281px #b65d8e, 1038px 1197px #b65d8e, 376px 1889px #b65d8e,
    1255px 1680px #b65d8e, 1008px 1316px #b65d8e, 1538px 1447px #b65d8e,
    1186px 874px #b65d8e, 1967px 640px #b65d8e, 1341px 19px #b65d8e, 29px 1732px #b65d8e,
    16px 1650px #b65d8e, 1021px 1075px #b65d8e, 723px 424px #b65d8e, 1175px 41px #b65d8e,
    494px 1957px #b65d8e, 1296px 431px #b65d8e, 175px 1507px #b65d8e, 831px 121px #b65d8e,
    498px 1947px #b65d8e, 617px 880px #b65d8e, 240px 403px #b65d8e;
}


@keyframes animStar {
    from {
        transform: translateY(0px);
    }
    to {
        transform: translateY(-2000px);
    }
}