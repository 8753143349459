@import url('https://fonts.googleapis.com/css2?family=Ubuntu&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oxygen&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Shadows+Into+Light+Two&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200&display=swap');


@font-face {
  font-family: "Circular Std Book";
  src: url("./Media//fonts/CircularStd-Book.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "D-DIN Condensed";
  src: url("./Media/fonts/D-DINCondensed-Bold.woff") format("woff");
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Mark Pro";
  src: url("./Media/fonts/MarkPro-Heavy.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}


* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}
:root {
  --MainColor :#ecdfbc;;
  --black-color: #1f1c1c;
  --ParagraphColor  :#7e7e7e;
  --Bg : #000000;

    /*FontsVars*/
    --font-primary :"Circular Std Book", sans-serif;
    --font-secondary: "Mark Pro", sans-serif;
    --font-tertiary: "D-DIN Condensed", sans-serif;
}
body{
 /*background-color: #f5f5f5;*/ 
 background-color: var(--Bg);
}

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
      background-color: var(--Bg);
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #7e7e7e; 
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #616161; 
  }